import React from "react";
import { about } from "../const";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import SideBar from "../master/SideBar";
import STRATEGICPLAN20212023 from "../../assets/about/Strategic Vision-final 27.07.2021.pdf";

const EmploymentContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="Work At St Maroun’s College" variant="h2" />
                <p>
                    The College strives to achieve educational excellence and recognizes that staff play an important role in the lives of its students and community.
                </p>

                <p>
                    If you are passionate and committed to student learning and welfare with a background in teaching primary or secondary, we welcome your enquiry.
                </p>

                <PageTitle title="Expressions of Interest" variant="h3" />
                <p>
                    For expressions of Interest please send the following documents to <a
                    href="mail:employment@stmarouns.nsw.edu.au">
                    employment@stmarouns.nsw.edu.au
                </a>
                    <p></p>
                    <ol>
                        <li>1. Cover letter
                        </li>
                        <li>2. Resume
                        </li>
                    </ol>
                </p>

                <PageTitle title="Current Openings" variant="h2"/>
                <p>
                    Casual teachers   - Primary and secondary.
                </p>


            </div>
            <SideBar items={about} title="About Us" />
        </ContentWrapper>
    </div>
);

export default EmploymentContent;
