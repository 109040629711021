import * as React from "react";
import Layout from "../../components/layout";
import heroImage from "../../assets/about/vision-mssion-values-hero.jpg";
import BannerImage from "../../components/master/BannerImage";
import EmploymentContent from "../../components/about/employmentContent";

const EmploymentPage = () => {
    return (
        <Layout>
            <BannerImage imagePath={heroImage} altText="Our Vision, Employment" />
            <EmploymentContent />
        </Layout>
    );
};

export default EmploymentPage;
